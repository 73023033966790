.boundary-wrapper {
    padding: 1px;
    box-sizing: border-box;
    border: 1px solid #c5d8ec;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgb(239 236 236 / 50%);
    height: 55vh;
}

.upload-kml-container {
    position: relative;
}

.upload-kml-container button {
    position: absolute;
    top: 10px;
    right: 5rem;
    z-index: 1;
}