.wrapper {
    padding: 8px 15px;
    box-sizing: border-box;
    border: 1px solid #c5d8ec;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgb(239 236 236 / 50%);
}

.button-div {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
}

.button-div button {
    margin: 0 2px;
}

.poi-list-table th {
    padding: 0 16px 16px 16px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
    display: none;
}

.select-account {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #5b5757;
    text-align: center;
}

.pagination-container {
    display: flex;
    justify-content: space-between;
}

.selected-count-wrapper {
    margin-top: 1rem;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    font-size: 1rem;
    border: 1px solid #cbcbcb;
    border-radius: 4px;
    top: 2px;
}

.e-multiselect.e-checkbox .e-multi-select-wrapper, .e-multiselect .e-multi-select-wrapper.e-down-icon {
    padding: 5px 14px;
}

.e-multiselect input.e-dropdownbase::placeholder {
    color: #797c7e;
    font-weight: 400;
    font-size: 1rem;
}

.e-popup .e-checkbox-wrapper .e-frame.e-check, .e-popup .e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: #1976d2;
    color: white;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: #1976d2;
}

.e-multi-select-wrapper .e-chips-close.e-close-hooker {
    margin-top: -30px;
}

.MuiTableCell-root {
    padding: 5px 16px !important;
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.col-sm-12 {
    margin-top: 25px !important;
}

tr {
    background: #fff;
}

.tab-font-wrap {
    width: 150px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sticky-checkbox-header {
    position: sticky;
    left: 0 !important;
    z-index: 3 !important;
    background: inherit;
}
.sticky {
    position: sticky;
    left: 0;
    z-index: 1;
    background: inherit;
}

.sticky-header-name {
    position: sticky;
    left: 70px !important;
    z-index: 3 !important;
    background: inherit;
    border-right: 1px solid #eee;
}
.sticky-name {
    position: sticky;
    left: 70px;
    z-index: 1;
    background: inherit;
    border-right: 1px solid #eee;
}

.MuiTableRow-root:hover{
    background-color: #eeeeee !important;
}

.MuiTableRow-root:hover {
    background-color: #eeeeee;
}