.wrapper {
  padding: 8px 15px;
  box-sizing: border-box;
  border: 1px solid #c5d8ec;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgb(239 236 236 / 50%);
}

.delivery-area-table th {
  padding: 0 16px 16px 16px !important;
}

.align-center {
  text-align: center;
}

.dialog-content p {
  color: black;
}

.edit-icon {
  color: #1976d2;
  height: 20px !important;
  cursor: pointer;
}
